import zlib from 'react-zlib-js';

export const getWorkstationLink = ({ study, keycloak }) => {
  const data = {
    messageName: "OpenStudies",
    requestType: "CONNECT",
    StudyUID: study.studyInstanceUid,
    wadoUri: process.env.GATSBY_WADO_URI,
    dicomWeb: process.env.GATSBY_DICOM_WEB_URL,
    authURL: process.env.GATSBY_KEYCLOAK_AUTH_URL,
    tokenURL: process.env.GATSBY_KEYCLOAK_TOKEN_URL,
    client_id: process.env.GATSBY_KEYCLOAK_CLIENT_ID,
    refreshToken: keycloak.refreshToken,
  };

  const params = Object.entries(data).map(entry => entry.join('=')).join('&');
  const query = zlib.deflateSync(params).toString('base64');
  // const retrieveParams = zlib.inflateSync(new Buffer.from(query, 'base64')).toString();
  return `a3dcon://?ahp_enc=${query}`;
};

export const openStudyInWorkstation = (url) => {
  window.open(url, '_blank');
};
