import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from 'react-keycloak';

import { useLazyQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import moment from 'moment-timezone';

import { Menu, MenuItem } from '@material-ui/core';
import {
  ArrowDropDown,
  ArrowDropUp,
  CreateTwoTone,
  CloudDownloadTwoTone,
  CloudUpload,
  DeleteTwoTone,
  InfoOutlined,
  InvertColors,
  InvertColorsTwoTone,
  Save,
  Sort,
  ToggleOn,
  ToggleOff,
  ViewComfy,
  ViewList,
  Web, DesktopWindows,
} from '@material-ui/icons';
import { GET_HOSPITAL_STUDIES_TO_LIST } from '../../graphql/queries';
import { SectionBar } from '../../componentsUI/SectionBar';
import { DeleteStudyDialog } from '../Study/modal/DeleteStudyDialog';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ContainerUI, ResponsiveListWrapperUI } from '../Common/styled/ContainerUI';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';
import { webViewerEndpoints } from '../../../config';
import { EditStudyDialog } from '../Study/modal/EditStudyDialog';
import { StudiesListView } from './StudiesListView';
import { StudiesGridView } from './StudiesGridView';
import { DATE_FORMAT } from '../../utils/functions';
import { StudiesFilterView } from './StudiesFilterView';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';
import { getWorkstationLink, openStudyInWorkstation } from '../../utils/WorkstationLink';

const sortData = {
  default: { field: 'CREATED_AT', direction: 'DESC' },
};

const { doctorApp, patientApp } = useApplicationInterface();

const attributes = [
  { key: 'select', width: 10, type: 'icon' },
  { key: 'patientId', label: 'patient.id', width: 100, filter: doctorApp },
  { key: 'patientName', label: 'patient.name', width: 125, filter: doctorApp },
  // { key: 'title', label: 'study.title', width: 175, filter: true },
  { key: 'createdAt', label: 'date', width: 75, format: DATE_FORMAT, align: 'center' },
  { key: 'modality', label: 'modality', width: 50, filter: true, align: 'center' },
  { key: 'numberOfSeries', label: 'series', width: 50, type: 'number', sortable: false, align: 'center' },
  { key: 'numberOfInstances', label: 'instances', width: 50, type: 'number', sortable: false, align: 'center' },
  { key: 'studyInstanceUid', label: 'ID', width: 150, hidden: true },
  { key: 'description', label: 'description', width: 225, filter: true },
];

export const StudiesView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const hospital = useSelector((state) => state.hospital);
  const user = useSelector((state) => state.userInterface.user);
  const studiesView = useSelector((state) => state.userInterface.studiesView);
  const [darkStyles, setDarkStyles] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState({});
  const filterElementRef = useRef();
  const [cursor, setCursor] = useState(null);
  const [orderByField, setOrderByField] = useState(sortData.default.field);
  const [orderByDirection, setOrderByDirection] = useState(sortData.default.direction);
  const [viewFilters, setViewFilters] = useState(studiesView === 'grid');
  const [studiesPerPage, setStudiesPerPage] = useState(25);
  const [keycloak] = useKeycloak();

  const setStudiesView = (view) => dispatch({
    type: 'SET_STUDY_LIST_VIEW',
    studiesView: { studiesView: view },
  });

  const toggleEditModal = () => setShowEditModal(!showEditModal);
  const toggleColorStyles = () => setDarkStyles(!darkStyles);
  const openDeleteModal = () => setShowDeleteModal(true);

  const usingWindows = navigator.appVersion.indexOf('Win') !== -1;
  const isBrowser = (() => typeof window !== 'undefined')();

  const orderByFields = [
    { label: 'patient.name', id: 'node.patientName', field: studiesView === 'grid' && 'PATIENT_NAME', direction: 'ASC', width: 370 },
    { label: 'study.title', id: 'node.title', field: 'TITLE', direction: 'ASC', width: 370 },
    { label: 'created.at', id: 'node.createdAt', field: 'CREATED_AT', direction: 'DESC', width: 120, format: 'DATEFORMAT' },
  ];

  const queryFilters = {};
  attributes
    .filter((field) => field.filter)
    .forEach((filter) => { queryFilters[filter.key] = filters[filter.key]; });

  const queryFirst = studiesView !== 'grid' ? 50 : studiesPerPage;

  const [getStudies, { loading, data, error, refetch, fetchMore }] = useLazyQuery(
    GET_HOSPITAL_STUDIES_TO_LIST,
    {
      variables: {
        hospitalUuid: hospital.uuid,
        ...(patientApp && { patientUuid: user.patientUuid }),
        first: queryFirst,
        cursor,
        createdAtAfter: moment(filters.fromDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        createdAtBefore: moment(filters.untilDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        ...(Object.keys(queryFilters).length && { filters: queryFilters }),
        orderBy: {
          field: orderByField,
          direction: orderByDirection,
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => getStudies(), [filters]);

  const study = (data && data.dicomStudies && selected.length > 0)
    && data.dicomStudies.edges[selected[0]] && data.dicomStudies.edges[selected[0]].node;

  const fetchMoreStudies = (fetchMoreCb) => {
    const { endCursor } = data.dicomStudies.pageInfo;

    fetchMore({
      query: GET_HOSPITAL_STUDIES_TO_LIST,
      variables: {
        hospitalUuid: hospital.uuid,
        first: 25,
        cursor: endCursor,
        orderBy: { field: orderByField, direction: orderByDirection },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.dicomStudies.edges;

        return newEdges.length ? {
          dicomStudies: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult.dicomStudies.__typename,
            totalCount: previousResult.dicomStudies.totalCount,
            pageInfo: fetchMoreResult.dicomStudies.pageInfo,
            edges: [...previousResult.dicomStudies.edges, ...newEdges],
          },
        } : previousResult;
      },
    })
      .then(({ data: fetchMoreData }) => (
        fetchMoreData.dicomStudies.pageInfo.hasNextPage && fetchMoreCb()
      ));
  };

  const reverseDirection = () => (
    orderByDirection === 'ASC' ? 'DESC' : 'ASC'
  );

  const multipleSelect = false;
  const handleSelected = (index) => {
    if (typeof index === 'undefined') {
      setSelected([]);
      return;
    }
    setSelected((selected.length && selected.includes(index))
      ? selected.filter((option) => option !== index)
      : [...multipleSelect ? selected : [], index]);
  };

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === orderByField) {
        setOrderByDirection(reverseDirection());
      } else {
        setOrderByDirection(value.direction);
      }

      setOrderByField(value.field);
      refetch().then(() => {
        setAnchorEl();
        setSelected([]);
      });
    }
  };

  const handleOpen = (evt) => {
    evt.stopPropagation();
    navigate(`/study/${study.uuid}`);
  };

  const openInWorkstation = () => {
    const url = getWorkstationLink({ study, keycloak });
    if (isBrowser && usingWindows) openStudyInWorkstation(url);
  };

  const openInWebViewer = () => {
    const url = `${webViewerEndpoints.getStudy}${study.studyInstanceUid}`;
    if (isBrowser) window.open(url, '_blank');
  };

  const handleOpenInWebViewer = (dicomStudy) => {
    const url = `${webViewerEndpoints.getStudy}${dicomStudy.studyInstanceUid}`;
    if (isBrowser) window.open(url, '_blank');
  };

  const handleGotoUpload = () => {
    navigate(`/study/upload/${hospital.uuid}?uth=1`);
  };

  const handleDownload = async () => {
    dispatch({ type: 'ADD_STUDY_DOWNLOAD', download: { uuid: study.uuid, type: 'study', name: study.title } });
  };

  const handleDownloadCD = async () => {
    dispatch({ type: 'ADD_STUDY_DOWNLOAD', download: { uuid: study.uuid, type: 'cd', name: study.title } });
  };

  const handleCloseDelete = () => {
    setShowDeleteModal(false);
    setSelected([]);
  };

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilters = (selection) => {
    setCursor(null);
    setSelected([]);
    setFilters(selection);
  };

  const toggleListView = () => {
    if (studiesView !== 'grid') {
      setCursor(null);
      // setViewAsGrid(true);
      setViewFilters(true);
      setStudiesView('grid');
      return;
    }
    setViewFilters(false);
    setTimeout(() => setStudiesView('list'), 500);
  };

  const toggleFilterView = () => {
    setViewFilters(!viewFilters);
  };

  const FilterIcon = viewFilters ? ToggleOn : ToggleOff;
  const ListIcon = studiesView === 'grid' ? ViewList : ViewComfy;
  const ColorsIcon = darkStyles ? InvertColorsTwoTone : InvertColors;
  const filterLabel = viewFilters ? 'hide.filters' : 'view.filters';
  const listLabel = studiesView === 'grid' ? 'view.as.list' : 'view.grid';
  const styleLabel = darkStyles ? 'theme.view' : 'dark.view';

  const uploadButtons = doctorApp ? [
    { name: 'upload.study', icon: CloudUpload, handleClick: handleGotoUpload, disabled: false },
    { name: 'divider1', type: 'divider' },
  ] : [];

  const updateButtons = doctorApp ? [
    { name: 'edit.study.info', icon: CreateTwoTone, handleClick: toggleEditModal, disabled: selected.length !== 1, hidden: studiesView === 'grid' },
    { name: 'delete', icon: DeleteTwoTone, handleClick: openDeleteModal, disabled: selected.length !== 1, hidden: studiesView === 'grid' },
  ] : [];

  const windowsButton = usingWindows ? [
    { name: 'open.in.workstation', icon: DesktopWindows, handleClick: openInWorkstation, disabled: selected.length !== 1 },
  ] : [];

  const cdLiteButton = hospital && hospital.enableCdLiteDownload ? [
    { name: 'download.cd.lite', icon: Save, handleClick: handleDownloadCD, disabled: selected.length !== 1 },
  ] : [];

  const buttons = [
    ...uploadButtons,
    { name: 'view.study.info', icon: InfoOutlined, handleClick: handleOpen, disabled: selected.length !== 1 },
    ...windowsButton,
    { name: 'open.in.viewer', icon: Web, handleClick: openInWebViewer, disabled: selected.length !== 1 },
    { name: 'download', icon: CloudDownloadTwoTone, handleClick: handleDownload, disabled: selected.length !== 1, hidden: studiesView !== 'grid' },
    ...cdLiteButton,
    ...updateButtons,
    { name: 'divider2', type: 'divider' },
    { name: filterLabel, icon: FilterIcon, handleClick: toggleFilterView, disabled: false },
    { name: listLabel, icon: ListIcon, handleClick: toggleListView },
    { name: styleLabel, icon: ColorsIcon, handleClick: toggleColorStyles, disabled: studiesView === 'grid', hidden: true },
    { name: 'sort.by', icon: Sort, handleClick: handleOrder },
  ];

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC')
      ? <ArrowDropDown className={className} />
      : <ArrowDropUp className={className} />
  );

  const errorMessage = networkErrorParse(error);

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
      </AlertWrapperUI>
    );
  }

  return (
    <ContainerUI>
      <Navbar>
        {doctorApp && !!study && showDeleteModal && (
          <DeleteStudyDialog
            open={showDeleteModal}
            onClose={handleCloseDelete}
            study={study}
            refetch={refetch}
          />
        )}
        {doctorApp && !!study && showEditModal && (
          <EditStudyDialog
            open={showEditModal}
            onClose={toggleEditModal}
            study={study}
          />
        )}
        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {orderByFields.filter((filter) => !!filter.field).map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === orderByField) && <OrderIcon direction={orderByDirection} />}
            </MenuItem>
          ))}
        </Menu>
        <SectionBar title="studies" items={buttons} />
      </Navbar>
      <ResponsiveListWrapperUI>
        {filters && (
          <StudiesFilterView
            attributes={attributes}
            filters={filters}
            viewFilters={viewFilters}
            queryFilters={queryFilters}
            handleFilters={handleFilters}
            reference={filterElementRef}
            dark={darkStyles}
          />
        )}
        {studiesView === 'grid' ? (
          <StudiesGridView
            attributes={attributes}
            items={data && data.dicomStudies}
            loading={loading}
            selected={selected}
            handleSelected={handleSelected}
            handleAction={handleOpenInWebViewer}
            filterElement={filterElementRef && filterElementRef.current}
            rowsPerPage={studiesPerPage}
            setRowsPerPage={setStudiesPerPage}
            setCursor={setCursor}
            dark={darkStyles}
          />
        ) : (
          <StudiesListView
            loading={loading}
            data={data}
            fetchMoreStudies={fetchMoreStudies}
            order={{ field: orderByField, direction: orderByDirection }}
            handleOrderBy={handleOrderBy}
            selected={selected}
            handleSelected={handleSelected}
            filterElement={filterElementRef && filterElementRef.current}
          />
        )}
      </ResponsiveListWrapperUI>
    </ContainerUI>
  );
};
